import React, { FC } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';

import ErrorPageTemplate from 'gatsby-theme-gaviscon/src/templates/ErrorPage/ErrorPage';

interface IPropsError404Page extends PageProps {}
const ErrorPage: FC<IPropsError404Page> = () => {
  const umbraco404Page = useStaticQuery(graphql`
    {
      siteSettings(lang: { eq: "fr-fr" }) {
        ...FragmentSiteSettings
      }
      commonSettings(lang: { eq: "fr-fr" }) {
        blocks {
          structure
          properties {
            ...FragmentDefaultCommonSettingsProps
          }
        }
      }
      header(lang: { eq: "fr-fr" }) {
        ...FragmentHeader
      }
      footer(lang: { eq: "fr-fr" }) {
        ...FragmentFooter
      }
      page: allUmbracoError404(filter: { lang: { eq: "fr-fr" } }) {
        nodes {
          seoMetaTitle
          seoMetaDescription
          seoMetaKeywords
          seoExternalHreflangs {
            key
            value
          }
          error
          title {
            value
          }
          description
          menu {
            name
            url
          }
          tags {
            name
          }
        }
      }
    }
  `);

  return <ErrorPageTemplate {...umbraco404Page} />;
};

export default ErrorPage;
